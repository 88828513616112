<template>
  <div>
    <v-dialog v-model="display" max-width="1000px" :fullscreen="$vuetify.breakpoint.xsOnly" persistent>
      <v-card class="pb-3">
        <v-card-title class="primary pt-2 pb-3" v-if="$vuetify.breakpoint.smAndUp">

        </v-card-title>

        <v-card-text class="pa-0">
          <v-layout row wrap align-center justify-center fill-height>
            <v-flex xs12 sm6 px-5>
              <v-img
                :class="{'my-5': $vuetify.breakpoint.xsOnly}"
                max-width="250px"
                contain
                :src="logo"
                :lazy-src="logo"
              />
            </v-flex>

            <v-flex xs12 sm6 px-5 v-if="company && $vuetify.breakpoint.smAndUp">
              <v-img
                max-width="250px"
                contain
                :src="image"
                :lazy-src="image"
              />
            </v-flex>
          </v-layout>

          <v-layout row wrap justify-center fill-height>
            <v-flex xs12 sm6 px-5 :class="{'border-right': $vuetify.breakpoint.smAndUp}">
              <p class="graphik-medium normalcase font-20 dark-grey--text">{{ $ml.get('validate_car_you_chose') }}</p>

              <p class="graphik-medium normalcase font-20 dark-grey--text mb-0">• {{ $ml.get('fields_car_model') }}</p>
              <p class="graphik-light normalcase font-20 dark-grey--text">{{ vehicle.model }}</p>

              <p class="graphik-medium normalcase font-20 dark-grey--text mb-0">• {{ $ml.get('fields_car_brand') }}</p>
              <p class="graphik-light normalcase font-20 dark-grey--text">{{ vehicle.brand }}</p>

              <p class="graphik-medium normalcase font-20 dark-grey--text mb-0">• {{ $ml.get('fields_car_uuid') }}</p>
              <p class="graphik-light normalcase font-20 dark-grey--text">{{ vehicle.full_version }}</p>
            </v-flex>

            <v-flex xs12 px-5 mt-3 v-if="$vuetify.breakpoint.xsOnly">
              <v-divider class="primary"></v-divider>
            </v-flex>

            <v-flex xs12 sm6 px-5 v-if="company && $vuetify.breakpoint.xsOnly">
              <v-img
                max-width="250px"
                contain
                :src="image"
                :lazy-src="image"
              />
            </v-flex>

            <v-flex xs12 sm6 px-5>
              <p class="graphik-medium normalcase font-20 dark-grey--text">{{ $ml.get('validate_car_insurance') }}</p>

              <p class="graphik-medium normalcase font-20 dark-grey--text mb-0">• {{ $ml.get('fields_car_model') }}</p>
              <p class="graphik-light normalcase font-20 dark-grey--text">{{ vehicle.original_model }}</p>

              <p class="graphik-medium normalcase font-20 dark-grey--text mb-0">• {{ $ml.get('fields_car_brand') }}</p>
              <p class="graphik-light normalcase font-20 dark-grey--text">{{ vehicle.original_brand }}</p>

              <p class="graphik-medium normalcase font-20 dark-grey--text mb-0">• {{ $ml.get('fields_car_uuid') }}</p>
              <p class="graphik-light normalcase font-20 dark-grey--text">{{ vehicle.original_full_version }}</p>
            </v-flex>

            <v-flex xs12 text-xs-center pt-5>
              <p class="graphik-medium normalcase font-20 dark-grey--text">{{ $ml.get('validate_car_yes_no') }}</p>
            </v-flex>

            <v-flex xs12 text-xs-center pb-5>
              <v-btn 
                color="primary"
                depressed dark 
                class="normalcase graphik-bold-italic my-0 px-5 ma-2 border-radius-5"
                @click="validateCar(true)"
              >{{ $ml.get('general_yes') }}</v-btn>

              <v-btn 
                color="primary"
                depressed dark 
                class="normalcase graphik-bold-italic my-0 px-5 ma-2 border-radius-5"
                @click="validateCar(false)"
              >{{ $ml.get('general_no') }}</v-btn>
            </v-flex>
          </v-layout>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog v-model="selected_no_dialog" max-width="750px">
      <v-card class="pb-3">
        <v-card-title class="primary pt-2 pb-3">

        </v-card-title>

        <v-card-text class="pa-0">
          <v-layout row wrap>
            <v-flex xs12 class="px-5 pt-3">
              <p
                :class="{'font-45': $vuetify.breakpoint.smAndUp, 'font-35 text-xs-left': $vuetify.breakpoint.xsOnly}"
                class="graphik-medium normalcase primary--text mb-0"
              >{{ $ml.get('validate_car_no') }}</p>
              <p
                :class="{'font-20': $vuetify.breakpoint.smAndUp, 'font-17 text-xs-left': $vuetify.breakpoint.xsOnly}"
                class="graphik-light normalcase dark-grey--text"
              >{{ $ml.get('validate_car_chose_no') }}</p>
            </v-flex>

            <v-flex xs12 class="px-5 pt-3">
              <v-img
                max-width="250px"
                position="center right"
                :src="logo"
                :lazy-src="logo"
                :class="{'mb-5': $vuetify.breakpoint.xsOnly}"
              />
            </v-flex>

            <v-flex xs12 :text-xs-right="$vuetify.breakpoint.smAndUp" :text-xs-center="$vuetify.breakpoint.xsOnly" class="px-5 pb-3">
              <v-btn 
                color="primary"
                depressed dark 
                class="normalcase graphik-bold-italic ma-0 border-radius-5"
                @click="$router.push('/cotizar/' + $route.params.type + '/' + $route.params.quote)"
              ><v-icon dark small class="mr-2">arrow_back</v-icon>{{ $ml.get('general_back') }}</v-btn>
            </v-flex>
          </v-layout>
        </v-card-text>
      </v-card>

      <message-dialog :dialog="g_dialog"></message-dialog>
      <loading-dialog :isLoading="g_isLoading"></loading-dialog>
    </v-dialog>
  </div>
</template>

<script>
export default {
  name: 'ValidateCarDialog',
  props: {
    show: Boolean,
    quote: String,
    quote_price: String,
    product: String
  },
  computed: {
    display: {
      get () {
        return this.show
      },
      set () {
        this.$emit('close')
      }
    },
    image () {
      return require('@/assets/img/companies/' + this.company + '-full.png')
    }
  },
  data () {
    return {
      selected_no_dialog: false,
      valid: null,
      logo: require('@/assets/img/brand/logo-purple.png'),
      company: '',
      vehicle: {}
    }
  },
  mounted () {
    this.getCar()
  },
  methods: {
    getCar () {
      this.g_isLoading = true
      this.$api.getCarValidate(
        this.quote,
        this.product,
        response => {
          this.g_isLoading = false
          this.company = response.body.data.company_internal_name
          this.vehicle = response.body.data
        },
        response => this.$errorHandling(response).then(() => {
          this.getCar()
        }, () => {})
      )
    },
    validateCar (value) {
      this.validate_car_dialog = false

      this.g_isLoading = true
      this.$api.validateCar(
        this.$route.params.quote_price,
        this.$route.params.product,
        { precise: value },
        () => {
          this.g_isLoading = false
          this.display = false
          if (!value) {
            this.$nextTick(() => {
              this.selected_no_dialog = true
            })  
          }
        },
        response => this.$errorHandling(response).then(() => {
          this.validateCar(value)
        }, () => {})
      )
    }
  }
}
</script>

<style scoped>
  .border-right {
    border-right: 1px solid #775fe1 !important;
  }
</style>
